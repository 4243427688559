<template>
  <div class="inspect-tasks-details">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="标样核查详情" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <div ref="pdfBoxRef">
          <!-- 表单 -->
          <ml-form disabled labelWidth="200px" style="width: 1200px; margin: 20px auto 40px">
            <!-- 提示标题 -->
            <el-divider content-position="left" style="font-weight: bold">标样核查详情信息</el-divider>
            <div class="globalTitle inspect-tasks-details-globalTitle">
              标样核查详情
              <el-image
                class="inspect-tasks-details-globalTitle-image"
                v-if="inspectOrderStatusImg"
                :src="inspectOrderStatusImg"
              />
            </div>
            <el-row>
              <el-col :span="12">
                <!-- 所属项目 -->
                <ml-input
                  prop="projectName"
                  label="所属项目:"
                  placeholder="请输入所属项目"
                  v-model.trim="InspectTasksDetailsForm.projectName"
                />
              </el-col>
              <el-col :span="12">
                <!-- 标样核查计划 -->
                <ml-input
                  prop="planName"
                  label="标样核查计划:"
                  placeholder="请输入标样核查计划"
                  v-model.trim="InspectTasksDetailsForm.planName"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <!-- 计划业务类型 -->
                <ml-input
                  prop="planBusinessTypeName"
                  label="计划业务类型:"
                  placeholder="请输入计划业务类型"
                  v-model.trim="InspectTasksDetailsForm.planBusinessTypeName"
                />
              </el-col>
              <el-col :span="12" v-if="InspectTasksDetailsForm.planBusinessType === '1'">
                <ml-input
                  prop="qualityControlType"
                  label="业务类型:"
                  placeholder="请输入业务类型"
                  v-model.trim="InspectTasksDetailsForm.qualityControlType"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <!-- 标样核查类型 -->
                <ml-input
                  prop="planType"
                  label="标样核查类型:"
                  placeholder="请输入标样核查类型"
                  v-model.trim="InspectTasksDetailsForm.planType"
                />
              </el-col>
              <el-col :span="12">
                <ml-input
                  prop="taskName"
                  label="标样核查名称:"
                  placeholder="请输入标样核查名称"
                  v-model.trim="InspectTasksDetailsForm.taskName"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <ml-input
                  prop="startTime"
                  label="预计开始时间:"
                  placeholder="请输入预计开始时间"
                  v-model.trim="InspectTasksDetailsForm.startTime"
                />
              </el-col>
              <el-col :span="12">
                <ml-input
                  prop="endTime"
                  label="预计结束时间:"
                  placeholder="请输入预计结束时间"
                  v-model.trim="InspectTasksDetailsForm.endTime"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <ml-input
                  prop="actualStartTime"
                  label="实际开始时间:"
                  placeholder=""
                  v-model.trim="InspectTasksDetailsForm.actualStartTime"
                />
              </el-col>
              <el-col :span="12">
                <ml-input
                  prop="endTime"
                  label="实际结束时间:"
                  placeholder=""
                  v-model.trim="InspectTasksDetailsForm.completeTime"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <ml-input
                  prop="managerUserName"
                  label="负责人:"
                  placeholder="请输入负责人"
                  v-model.trim="InspectTasksDetailsForm.managerUserName"
                />
              </el-col>
              <el-col :span="12">
                <ml-input
                  prop="userName"
                  label="执行人:"
                  placeholder="请输入执行人"
                  v-model.trim="InspectTasksDetailsForm.userName"
                />
              </el-col>
            </el-row>

            <ml-input
              prop="content"
              label="标样核查内容:"
              placeholder="请输入标样核查内容"
              type="textarea"
              :rows="5"
              v-model.trim="InspectTasksDetailsForm.content"
            />
          </ml-form>

          <ml-form
            labelWidth="200px"
            style="width: 1200px; margin: 20px auto 40px"
            :model="InspectTasksDetailsForm"
          >
            <div class="globalTitle" v-if="InspectTasksDetailsForm.attachVos.length > 0">附件</div>
            <el-form-item label="附件:" v-if="InspectTasksDetailsForm.attachVos.length > 0">
              <ml-list
                fileName="fileName"
                :showDelete="false"
                :showDown="true"
                :list="InspectTasksDetailsForm.attachVos"
              />
            </el-form-item>

            <div class="globalTitle" v-if="deviceErrorLogsByError.length > 0">流程进度</div>
            <el-form-item v-if="deviceErrorLogsByError.length > 0" style="margin-bottom: 60px">
              <process-schedule
                disabled
                v-for="(item, index) in deviceErrorLogsByError"
                :key="item"
                :item="item"
                :state="
                  InspectTasksDetailsForm.inspectOrderStatus === '2'
                    ? index === deviceErrorLogsByError.length - 1
                    : index === deviceErrorLogsByError.length - 2
                "
              />
            </el-form-item>

            <!-- 处理故障按钮组 -->
            <ml-button
              native-type="button"
              submitText="返回上一页"
              submitIcon="el-icon-circle-close"
              submitType="default"
              @click-submit="clickCancelFultServiceCenter"
              cancelText="导出"
              cancelIcon="el-icon-download"
              cancelType="primary"
              @click-cancel="clickDownloadExcel"
            >
              <el-button
                v-if="
                  ['0', '-1'].includes(InspectTasksDetailsForm.inspectOrderStatus) &&
                  isAudit(InspectTasksDetailsForm.inspectUserVos)
                "
                icon="el-icon-upload2"
                type="primary"
                @click="clickSubmitReport"
              >
                提交报告
              </el-button>
              <el-button
                v-if="
                  InspectTasksDetailsForm.inspectOrderStatus === '1' &&
                  isAmibaManager(InspectTasksDetailsForm)
                "
                icon="el-icon-check"
                type="primary"
                @click="clickCheck(InspectTasksDetailsForm, true)"
              >
                通过
              </el-button>
              <el-button
                v-if="
                  InspectTasksDetailsForm.inspectOrderStatus === '1' &&
                  isAmibaManager(InspectTasksDetailsForm)
                "
                icon="el-icon-refresh"
                type="danger"
                @click="clickCheck(InspectTasksDetailsForm)"
              >
                退回
              </el-button>
            </ml-button>

            <!-- <ml-button
                cancelIcon="el-icon-circle-close"
                native-type="button"
                cancelText="返回上一页"
                @click-cancel="clickCancelFultServiceCenter"
              /> -->
          </ml-form>
        </div>
      </el-scrollbar>
    </ml-tip>
    <!-- 办结提示框 -->
    <ml-dialog
      width="600px"
      ref="errorRecordCompleteDialogRef"
      :title="errorRecordCompleteFormData.title"
      @click-submit="errorRecordCompleteDialogSubmit"
      @click-close="errorRecordCompleteDialogClose"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="errorRecordCompleteForm"
          :rules="errorRecordCompleteFormRule"
          ref="errorRecordCompleteFormRef"
        >
          <!-- 办结描述 -->
          <ml-input
            prop="content"
            :placeholder="`请输入${errorRecordCompleteFormData.title}描述`"
            :label="`${errorRecordCompleteFormData.title}描述`"
            type="textarea"
            :rows="5"
            v-model="errorRecordCompleteForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="errorRecordCompleteForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 提交报告提示框 -->
    <ml-dialog
      width="600px"
      ref="submitReportDialogRef"
      title="提交报告"
      @click-submit="submitReportDialogSubmit"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="submitReportForm"
          :rules="submitReportFormRule"
          ref="submitReportFormRef"
        >
          <!-- 办结描述 -->
          <ml-date-picker
            prop="startTime"
            placeholder="请选择开始时间"
            label="开始时间:"
            type="datetime"
            :disabled-date="disabledStartDate"
            v-model="submitReportForm.startTime"
          />
          <ml-date-picker
            prop="endTime"
            placeholder="请选择结束时间"
            label="结束时间:"
            type="datetime"
            :disabled-date="disabledEndDate"
            v-model="submitReportForm.endTime"
          />
          <ml-input
            prop="content"
            placeholder="请输入内容描述"
            label="内容描述"
            type="textarea"
            :rows="5"
            v-model="submitReportForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="submitReportForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSubmitReportSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>
  
  <script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { tabberHeight, searchParams, formetData } from '@/utils'
import ProcessSchedule from '@components/processSchedule.vue'

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
// 巡检工单状态（-1 审核退回 0待处理 1待审核 2 已办结）
// 待审核
import isAuditImg from '../../../assets/image/is-audit.png'
// 待处理
import isDealImg from '../../../assets/image/is-deal.png'
// 已办结
import completeImg from '../../../assets/image/complete.png'
import { BASEURL, FILEUPLOADURL } from '@API'

export default {
  name: 'InspectTasksDetails',
  components: {
    ProcessSchedule
  },
  setup() {
    const { dispatch, getters, commit } = useStore()
    const router = useRouter()
    const route = useRoute()
    const { inspectTaskId } = route.query
    const userInfo = computed(() => getters.getUser)

    // 提交报告
    const submitReportDialogRef = ref()
    const submitReportFormRef = ref()
    const submitReportForm = reactive({
      startTime: '',
      endTime: '',
      content: '',
      attachVos: [],
      inspectTaskId: ''
    })
    // 校验
    const submitReportFormRule = {
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }
    const disabledStartDate = value => {
      if (submitReportForm.endTime) {
        return value.getTime() > new Date(submitReportForm.endTime).getTime()
      }
      return false
    }

    const disabledEndDate = value => {
      if (submitReportForm.startTime) {
        return (
          value.getTime() < new Date(submitReportForm.startTime).getTime() - 24 * 60 * 60 * 1000
        )
      }
      return false
    }
    /* 开始 表单 **********************************/

    // 表单配置
    const InspectTasksDetailsForm = reactive({
      companyId: '',
      inspectTaskId: '',
      projectName: '',
      planName: '',
      planType: '',
      taskName: '',
      startTime: '',
      endTime: '',
      managerUserName: '',
      userName: '',
      content: '',
      inspectOrderStatus: '',
      attachVos: [],
      actualStartTime: '',
      completeTime: '',
      inspectUserVos: [],
      qualityControlType: '',
      planBusinessType: '',
      planBusinessTypeName: ''
    })
    const inspectOrderStatusImg = ref()
    watch(
      () => InspectTasksDetailsForm.inspectOrderStatus,
      newvalue => {
        if (newvalue) {
          inspectOrderStatusImg.value =
            newvalue === '2' ? completeImg : newvalue === '1' ? isAuditImg : isDealImg
        }
      }
    )
    const deviceErrorLogsByError = ref([])

    const isAudit = (arr = []) => {
      const { userId } = userInfo.value
      const userIdArr = arr ? arr.map(item => item.userId) : []
      return userIdArr.includes(userId)
    }

    const isAmibaManager = row => {
      const { userType, companyId } = userInfo.value
      return userType === 'amiba_manager' && companyId === row.companyId
    }

    // 取消表单
    const clickCancelFultServiceCenter = () => {
      router.go(-1)
      // router.push({ path: '/faultServiceCenter' })
    }
    // 获取单个故障信息
    const getFaultServiceCenterDetails = async () => {
      // 查看故障详情
      await dispatch('fetchGetInspectTask', inspectTaskId).then(data => {
        if (data && data.code === 200 && data.data) {
          Object.keys(InspectTasksDetailsForm).map(item => {
            if (['attachVos'].includes(item)) {
              InspectTasksDetailsForm[item] = data.data[item] || []
            } else {
              InspectTasksDetailsForm[item] = data.data[item]
            }
          })
          InspectTasksDetailsForm['planBusinessTypeName'] =
            InspectTasksDetailsForm.planBusinessType === '1' ? '质检' : '巡检'
        }
      })
      // 查询故障流程进度
      await dispatch('fetchGetInspectLogsByTask', { inspectTaskId }).then(data => {
        if (data && data.code === 200) {
          deviceErrorLogsByError.value = data.data
        }
      })
    }

    // 导出 Excel
    const pdfBoxRef = ref()
    const clickDownloadExcel = () => {
      nextTick(() => {
        const w = pdfBoxRef.value.offsetWidth
        const h = pdfBoxRef.value.offsetHeight + 60
        html2canvas(pdfBoxRef.value, {
          useCORS: true,
          y: -100,
          x: (w - 1660) / 2,
          width: 1600,
          height: h
        }).then(canvas => {
          const contentW = canvas.width
          const contentH = canvas.height
          // 每页的高度
          const pageH = (contentW / 592.25) * 841.89
          // 款生成PDF的html高度
          let leftH = contentH
          // 页面偏移
          let position = 0
          // A4 尺寸
          const imgW = 595.28
          const imgH = (592.28 / contentW) * contentH
          //返回图片URL，参数：图片格式和清晰度(0-1)
          const pageData = canvas.toDataURL('image/png', 1.0)
          const pdf = new jsPdf('', 'pt', 'a4', true)

          // //方向默认竖直，尺寸ponits，格式a4【595.28,841.89]
          setTimeout(() => {
            // //需要dataUrl格式
            if (leftH < pageH) {
              pdf.addImage(pageData, 'png', 0, 0, imgW, imgH)
            } else {
              while (leftH > 0) {
                pdf.addImage(pageData, 'png', 0, position, imgW, imgH)
                leftH -= pageH
                position -= 841.89
                // 避免添加空白页面
                if (leftH > 0) {
                  pdf.addImage()
                }
              }
            }
            pdf.save(`${InspectTasksDetailsForm.taskName || '巡检详情'}.pdf`)
          }, 600)
        })
      })
    }

    /* 结束 用户表单 **********************************/

    /** 办结 开始***********************************/
    /** 办结 结束************************************/

    /** 提交报告 开始***********************************/
    const clickSubmitReport = () => {
      submitReportForm.inspectTaskId = InspectTasksDetailsForm.inspectTaskId
      submitReportDialogRef.value.showDialog = true
    }
    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSubmitReportSuccess = files => {
      if (files.data) {
        submitReportForm.attachVos = [...submitReportForm.attachVos, ...files.data]
      }
    }
    // 提交报告
    const submitReportDialogSubmit = () => {
      submitReportFormRef.value.CustomFormRef.validate()
        .then(() => {
          const nParams = searchParams(submitReportForm, ['startTime', 'endTime'], true)
          const startTime = submitReportForm.startTime
          const endTime = submitReportForm.endTime
          if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
            commit('setError', {
              status: true,
              title: '',
              message: '开始时间不能大于结束时间',
              type: 'warning'
            })
            return
          }
          if (submitReportForm.startTime) {
            nParams.startTime = formetData(submitReportForm.startTime)
          }
          if (submitReportForm.endTime) {
            nParams.endTime = formetData(submitReportForm.endTime)
          }
          dispatch('fetchAddInspectRecord', nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              submitReportDialogRef.value.showDialog = false
              getFaultServiceCenterDetails()
              router.go(-1)
            }
          })
        })
        .catch(() => {})
    }
    /** 提交报告 结束***********************************/
    // 通过，退回对话框 el
    const errorRecordCompleteDialogRef = ref()
    // 表单 el
    const errorRecordCompleteFormRef = ref()
    const errorRecordCompleteFormData = reactive({
      title: ''
    })
    const errorRecordCompleteForm = reactive({
      content: '',
      attachVos: []
    })
    const onSuccess = files => {
      if (files.data) {
        errorRecordCompleteForm.attachVos = [...errorRecordCompleteForm.attachVos, ...files.data]
      }
    }
    // 通过，退回按钮
    const clickCheck = (row, isComplete) => {
      errorRecordCompleteFormData.inspectTaskId = row.inspectTaskId
      errorRecordCompleteFormData.title = isComplete ? '通过' : '退回'
      errorRecordCompleteDialogRef.value.showDialog = true
    }
    // 校验
    const errorRecordCompleteFormRule = {
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }

    // 取消对证框
    const errorRecordCompleteDialogClose = () => {
      errorRecordCompleteForm.attachVos = []
    }

    // 通过，退回确认对证框
    const errorRecordCompleteDialogSubmit = () => {
      const isComplete = errorRecordCompleteFormData.title === '通过'
      const actionName = isComplete ? 'fetchCheckInspectTask' : 'fetchCheckInspectTask'
      const nParams = searchParams(errorRecordCompleteForm)
      nParams.inspectTaskId = errorRecordCompleteFormData.inspectTaskId
      if (isComplete) {
        nParams.status = 'Y'
      } else {
        nParams.status = 'N'
      }
      errorRecordCompleteFormRef.value.CustomFormRef.validate()
        .then(() => {
          dispatch(actionName, nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })

              errorRecordCompleteDialogRef.value.showDialog = false
              getFaultServiceCenterDetails()
              router.go(-1)
            }
          })
        })
        .catch(() => {})
    }

    onMounted(async () => {
      // 获取单个故障信息
      await getFaultServiceCenterDetails()
    })
    return {
      pdfBoxRef,
      clickDownloadExcel,
      InspectTasksDetailsForm,
      clickCancelFultServiceCenter,
      tabberHeight,
      deviceErrorLogsByError,
      inspectOrderStatusImg,
      isAudit,
      clickSubmitReport,
      submitReportFormRule,
      submitReportFormRef,
      submitReportForm,
      disabledStartDate,
      disabledEndDate,
      submitReportDialogSubmit,
      submitReportDialogRef,
      action,
      onSubmitReportSuccess,
      isAmibaManager,
      clickCheck,
      errorRecordCompleteDialogRef,
      errorRecordCompleteFormRef,
      errorRecordCompleteForm,
      errorRecordCompleteFormRule,
      onSuccess,
      errorRecordCompleteFormData,
      errorRecordCompleteDialogSubmit,
      errorRecordCompleteDialogClose
    }
  }
}
</script>
  
  <style lang="scss">
.inspect-tasks-details {
  position: relative;
  &-globalTitle {
    position: relative;
    z-index: 1;
    &-image {
      position: absolute;
      width: 100px;
      top: -50px;
      left: 150px;
    }
  }
  .btn-box {
    display: flex;
  }
}
</style>
  